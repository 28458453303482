import { CFormLabel } from '@coreui/react-pro'
import { DragHandle, South } from '@mui/icons-material'
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import ReactPlayer from 'react-player'
import ConfirmationModal from 'src/components/controlCenter/ConfirmationModal'
import { CustomReactSelect, selectTypes } from 'src/components/controlCenter/FormComponents'
import { sortFn } from 'src/components/controlCenter/backgroundSlides/TimeLineDnD'
import ImageRenderComponent from 'src/components/controlCenter/imageUpload/ImageRenderComponent'
import { transformFetchedEntity } from 'src/config/genericForm/fetch/fetchEntityTransformer'
import { GetSettingsData } from 'src/services/APIs/Settings/GetApis'
import { GENERIC_APIS } from 'src/services/genericApis'
import { GetDetailedEntity, SaveEntity } from 'src/utilities/apiGetters'
import {
  ALL_ENTITY_TYPES_FROM_CONSTANTS as ALL_ENTITY_TYPES,
  OOH_MSG_TYPES,
} from 'src/utilities/constants'
import { loadOptionsForAsyncPaginate } from 'src/utilities/generalUtils'
import { array_move } from 'src/views/curatedLists/utils/curatedListData-utils'
import { CustomAsyncPaginate } from 'src/views/plugins/calendar/components/CustomSelect'
import ShowEnlargedOOH from './ShowEnlargedOOH'

export default function ReorderOohMsgs({
  listingData: states,
  handleClose,
  handleRefresh,
  customOptions,
}) {
  const [reOrderedOoh, setReordedOoh] = useState([])
  const [changes, setChanges] = useState(false)
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [selectedClinic, setSelectedClinic] = useState()
  const [allOohMsgs, setAllOohMsgs] = useState([])
  const [loading, setLoading] = useState(false)
  const [loadingDetails, setLoadingDetails] = useState({
    mentor: false,
    clinic: false,
  })
  const [selectedMentor, setSelectedMentor] = useState()
  const [selectedOOH, setSelectedOOH] = useState()
  const [showEnlargedMedia, setShowEnlargedMedia] = useState(false)

  async function saveOrder() {
    const response = await Promise.all(
      reOrderedOoh.map(async (oohMsg, idx) => {
        const mentalStateToSave = await GetDetailedEntity(ALL_ENTITY_TYPES.oohMsg, oohMsg)
        const resp = await SaveEntity(
          ALL_ENTITY_TYPES.oohMsg,
          { ...mentalStateToSave, orderId: idx },
          () => {},
          () => {},
        )
      }),
    )
    handleRefresh()
    handleClose()
  }

  async function getOohMsg() {
    const oohResponse = await GetSettingsData(GENERIC_APIS.entity.getPaginated, {
      entityType: ALL_ENTITY_TYPES.oohMsg,
      page: 0,
      pageSize: 999,
      // enabled: true,
      clinicId: selectedClinic?.id,
      mentorId: selectedMentor?.id,
    })
    if (oohResponse.status === 200) {
      const oohResponseData = oohResponse.data.content
      setAllOohMsgs(oohResponseData)
      setReordedOoh(
        oohResponseData
          .filter((oohMsg) => oohMsg.enabled)
          .sort((o1, o2) => sortFn(o1.orderId, o2.orderId))
          .map((oohMsg, idx) => ({ ...oohMsg, orderId: idx })),
      )
    }
  }

  function handleDragEnd(results) {
    const { source, destination } = results
    if (source && destination && source?.index !== destination?.index) {
      setReordedOoh((prev) => {
        const newArray = array_move(prev, source.index, destination.index)
        var toReturn = false
        newArray?.forEach((oohMsg, idx) => {
          if (!toReturn) {
            if (oohMsg.id !== allOohMsgs?.[idx]?.id) {
              toReturn = true
            }
          }
        })
        setChanges(toReturn)
        return newArray
      })
    }
  }

  function openEnlargedMedia(oohMsg) {
    setSelectedOOH(oohMsg)
    setShowEnlargedMedia(true)
  }

  function closeEnlargedMedia() {
    setShowEnlargedMedia(false)
  }

  async function changeClinic(value) {
    setLoadingDetails((prev) => ({ ...prev, clinic: true }))

    console.log({ value })

    const clinicDetails = await GetDetailedEntity(ALL_ENTITY_TYPES?.clinic, { id: value?.id })
    const clinicDataToSet = await transformFetchedEntity(ALL_ENTITY_TYPES.clinic, clinicDetails)
    if (!!clinicDetails) {
      setSelectedClinic(clinicDataToSet)
    }
    setLoadingDetails((prev) => ({ ...prev, clinic: false }))
  }

  async function Setup() {
    setLoading(true)
    const functions = [getOohMsg()]
    await Promise.all(functions)
    setLoading(false)
  }

  useEffect(() => {
    console.log({ selectedClinic, selectedMentor })
    if (!!selectedClinic && !!selectedMentor) {
      Setup()
    }
  }, [selectedClinic, selectedMentor])
  return (
    <>
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <span>Re-Order OOH Messages</span>
      </DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        <CustomReactSelect
          id="clinicId"
          value={selectedClinic}
          isLoading={loadingDetails?.clinic}
          options={customOptions?.clinics || []}
          getOptionValue={(object) => object?.id}
          getOptionLabel={(object) => object?.title || object?.id}
          label="Clinic"
          onChangeEvent={(e) => changeClinic(e.target.value)}
          selectType={selectTypes.object}
          placeholder="Select a clinic..."
          required
        />
        {/* <div>
          <label>Clinic</label>
          <CustomAsyncPaginate
            value={selectedClinic}
            menuPlacement="auto"
            onChange={changeClinic}
            isLoading={loadingDetails?.clinic}
            loadOptions={loadOptionsForAsyncPaginate}
            getOptionValue={(object) => object.id}
            getOptionLabel={(object) => object.title}
            placeholder="Select a clinc..."
            debounceTimeout={500}
            additional={{
              page: 0,
              getFunction: GetSettingsData,
              otherParams: {
                entityType: ALL_ENTITY_TYPES?.clinic,
                enabled: true,
              },
              apiUrl: GENERIC_APIS.entity.getPaginated,
              setLoading: (val) => setLoadingDetails((prev) => ({ ...prev, clinic: val })),
            }}
            required
          />
        </div> */}
        {!!selectedClinic && (
          <div>
            <CFormLabel>Mentor</CFormLabel>
            <CustomReactSelect
              id="mentorId"
              value={selectedMentor}
              isLoading={loadingDetails?.clinic}
              options={(
                [...(selectedClinic?.doctors || []), ...(selectedClinic?.therapists || [])] || []
              )?.sort((a, b) => a.title.localeCompare(b.title))}
              getOptionValue={(object) => object?.id}
              getOptionLabel={(object) => object?.title || object?.id}
              onChange={(value) => setSelectedMentor(value)}
              selectType={selectTypes.object}
              placeholder="Select a mentor..."
              required
            />
          </div>
        )}
        {loading ? (
          <CircularProgress />
        ) : (
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable id="dnd-faq-category" droppableId="Cats" type="group">
              {(provided) => (
                <List
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  sx={{ bgcolor: 'background.paper' }}
                >
                  <ListItem>
                    <ListItemAvatar sx={listItemStyles}>
                      <Box>
                        <span>#</span>
                        <South fontSize="small" />
                      </Box>
                      <Box sx={{ width: '18px' }}></Box>
                    </ListItemAvatar>
                    <ListItemText primary="OOH Msg" />
                  </ListItem>
                  {reOrderedOoh?.map((oohMsg, idx) => (
                    <Draggable key={oohMsg.id} draggableId={`${oohMsg.id}`} index={idx}>
                      {(provided) => (
                        <div
                          {...provided.dragHandleProps}
                          {...provided.draggableProps}
                          ref={provided.innerRef}
                        >
                          <ListItemButton id={oohMsg.id}>
                            <ListItemAvatar sx={listItemStyles}>
                              <span style={{ marginRight: '30px' }}>{idx}</span>
                              <Avatar
                                onClick={(e) => {
                                  e.stopPropagation()
                                  openEnlargedMedia(oohMsg)
                                }}
                                sx={{ width: '100px', height: '100px', postion: 'relative' }}
                                variant="rounded"
                              >
                                <div
                                  style={{
                                    position: 'absolute',
                                    zIndex: '2',
                                    height: '100%',
                                    width: '100%'
                                  }}
                                ></div>
                                <div
                                  style={{
                                    position: 'absolute',
                                    zIndex: 0,
                                    height: '100%',
                                    width: '100%'
                                  }}
                                >
                                  {oohMsg?.imageType === OOH_MSG_TYPES.image ? (
                                    <ImageRenderComponent src={oohMsg?.imageId} />
                                  ) : (
                                    <ReactPlayer
                                      url={oohMsg?.youtubeUrl}
                                      style={{
                                        height: '100%',
                                        width: '100%',
                                        padding: '10px',
                                      }}
                                    />
                                  )}
                                </div>
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={oohMsg?.title || 'Title Not Set'} />
                            <ListItemIcon>
                              <DragHandle />
                            </ListItemIcon>
                          </ListItemButton>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </List>
              )}
            </Droppable>
          </DragDropContext>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="success"
          disabled={!changes}
          onClick={() => setShowConfirmation(true)}
        >
          Save
        </Button>
      </DialogActions>
      <ConfirmationModal
        action="submit"
        body="Do you want to save"
        visibility={showConfirmation}
        visibilitySetter={setShowConfirmation}
        onSubmitFunctions={[() => saveOrder()]}
      />
      <Dialog
        PaperProps={{ sx: { maxWidth: '650px', background: 'transparent', boxShadow: 'unset' } }}
        open={showEnlargedMedia}
        onClose={closeEnlargedMedia}
      >
        <DialogContent>
          <ShowEnlargedOOH oohMsg={selectedOOH} />
        </DialogContent>
      </Dialog>
    </>
  )
}

const listItemStyles = {
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
  gap: 2,
  px: 2,
}
