import { Settings } from '@mui/icons-material'
import { Button, Dialog, DialogTitle, IconButton, Menu, MenuItem, Tab } from '@mui/material'
import { debounce } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import CCExtendedCSmartTable from 'src/components/controlCenter/CCExtendedCSmartTable'
import CCPagination from 'src/components/controlCenter/CCPagination'
import ConfirmationModal, {
  defaultConfirmationModalProps,
} from 'src/components/controlCenter/ConfirmationModal'
import GridListingComponent from 'src/components/controlCenter/GridListingComponent'
import {
  POPUP_ACTION_STATES_TYPES,
  PopupActionStatesComponents,
  StatsTabsToApiParamsMapper,
  conditionalColumns,
  customHeaderComponents,
  customScopedActions,
  customScopedColumns,
  dontFetchUserNames,
  editActionLabelChanger,
  entityTitle,
  getExtraDataForFormAndListing,
  getFullSaveObject,
  navDetails,
  onViewAddFunction as onViewAddFunctionFromConfig,
  urlPrmToApiParam,
} from 'src/config/common/genericListingAndFormConfig'
import { postFunctionSettings } from 'src/config/genericForm/post/postDataConfig'
import { getExcludedListOfColumnsForSmartTable } from 'src/config/genericListing/columnsAndHeaders/getExcludedListOfColumnsForSmartTable'
import { getGridCards } from 'src/config/genericListing/gridCards'
import { entityListingSettings } from 'src/config/genericListing/listingSettings'
import { modifyListingData } from 'src/config/genericListing/modifyListingData'
import { GetSettingsData } from 'src/services/APIs/Settings/GetApis'
import { GENERIC_APIS } from 'src/services/genericApis'
import { EnableDisableEntity } from 'src/utilities/apiGetters'
import { LISTING_VIEWS } from 'src/utilities/constants'
import { giveGenericHandleChangedData } from 'src/utilities/generalUtils'
import { useAppSettingsStore } from 'src/utilities/hooks/useAppSettingsStore'
import { quoteOptionsList } from 'src/views/features/quotes/utils/quotes-utils'
import UploadCsvDialog from './components/UploadCsvDialog'
import UserStatsDialog from './components/UserStatsDialog'
import {
  CoolerTabs,
  GenericListingPageDialogPaper,
  GenericListingPageSmallDialogPaper,
} from './components/styledComponents'
import { getCustomFunctionData } from './generic-form-utils'

export default function EntityListingComponent({
  apiParam: propApiParam,
  customListingApiParams = {},
  hideFilterTableHeader,
  modifyNewObjectData = (obj) => obj,
  hideTableFilters = [],
  viewOnly = false,
  forceSize,
  customActionsOverride,
  apiUrlToOverrideFromProps,
  onRowClickOverride,
  clickableRows = true,
  hideAddEntityButton = false,
  refreshDataCountFromProps = 1,
  hideFilterButton = false,
  hideCsvSaveButton = false,
  onViewAddFunction: onViewAddFunctionFromProps,
  getListingData = async (apiUrlToUse, finalApiParams) => {
    return await GetSettingsData(apiUrlToUse, finalApiParams)
  },
  propScopedColumns,
  propColumns,
  propDefaultExcludedColumns,
}) {
  const routeParams = useParams()
  const { apiParam: tempParam } = routeParams

  const location = useLocation()
  const queryString = location.search
  const urlParams = new URLSearchParams(queryString)
  const rawApiParam = propApiParam || tempParam || urlParams.get('apiParam')
  const apiParam = urlPrmToApiParam(rawApiParam, location)
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])
  const [showEdit, setShowEdit] = useState(false)
  const [itemToEdit, setItemToEdit] = useState(null)
  const [pagination, setPagination] = useState({
    pageSize: quoteOptionsList[0],
    pageNumber: 0,
    offset: 10,
  })
  const [totalPages, setTotalPages] = useState(-1)
  const columns = propColumns || conditionalColumns(apiParam)
  const showTabs = window.location.href.split('/').some((route) => route === 'user-score')
  const tableComponentsLength = tableComponents().props?.children?.filter((child) => !!child).length
  const [settingsAnchor, setSettingsAnchor] = useState(null)
  const [filters, setFilters] = useState({})
  const [customFormOptions, setCustomFormOptions] = useState({})
  const [sortData, setSortData] = useState({})
  const [mode, setMode] = useState('View')
  const [confirmationModalProps, setConfirmationModalProps] = useState(
    defaultConfirmationModalProps,
  )
  const [store, dispatch] = useAppSettingsStore()
  const [totalItems, setTotalItems] = useState(0)
  const [popupActionsStates, setPopupActionsStates] = useState({
    open: false,
    type: null,
    itemInPerspective: null,
  })
  const [listingView, setListingView] = useState(LISTING_VIEWS.list)
  const [filtersAnchorEl, setFiltersAnchorEl] = useState(false)
  const [changedData, setChangedData] = useState(false)
  const [showCsvDialog, setShowCsvDialog] = useState(false)

  const onViewAddFunction = onViewAddFunctionFromProps || onViewAddFunctionFromConfig

  const listingSettings = useMemo(() => {
    return entityListingSettings(apiParam, rawApiParam, {
      store,
      getUrls: {
        paginated: GENERIC_APIS.entity.getPaginated,
        normal: GENERIC_APIS.entity.getSummary,
      },
      navigate,
    })
  }, [rawApiParam, navigate, store, apiParam])

  const postDataConfig = useMemo(() => {
    return postFunctionSettings(apiParam, {}, {})
  }, [rawApiParam])

  const PopActionsStatesComponent = useMemo(() => {
    return PopupActionStatesComponents(popupActionsStates.type)
  }, [popupActionsStates.type])

  const customScopedActionsFunction = customActionsOverride || customScopedActions

  async function getData(
    page = pagination.pageNumber,
    size = pagination.pageSize,
    apiSearch = filters?.search,
    apiFilters = filters,
    sortProps = sortData,
  ) {
    var filtersToUse = apiFilters
    if (!!apiFilters?.search) {
      const { search, ...tempFilters } = filtersToUse
      filtersToUse = tempFilters
    }
    const finalFilters = Object.entries(filtersToUse || {}).reduce((acc, [key, val]) => {
      if (Array.isArray(val)) {
        if (val?.length === 0) {
          return { ...acc, [key]: null }
        }
        return { ...acc, [key]: `${val.join(',')},` }
      } else {
        return { ...acc, [key]: val }
      }
    }, {})
    const finalParams = listingSettings?.overrideDefaultParams({
      page: page,
      size: forceSize || size,
      entityType: apiParam,
      ...customListingApiParams,
      ...finalFilters,
      ...sortProps,
      search: apiSearch,
    })
    const apiUrlToUse = apiUrlToOverrideFromProps || listingSettings?.customGetURL

    const response = await getListingData(apiUrlToUse, finalParams)
    if (response) {
      const responseData = !!listingSettings?.pagination ? response.data?.content : response.data
      const modifiedListingData = await modifyListingData(apiParam, responseData)
      setData(
        modifiedListingData?.map((cont) => ({
          ...cont,
          orderId: cont?.orderId !== undefined ? cont.orderId : null,
        })),
      )
      !!listingSettings?.pagination && setPagination(response.data?.pageable)
      !!listingSettings?.pagination && setTotalPages(response.data?.totalPages || 0)
      !!listingSettings?.pagination
        ? setTotalItems(response.data?.totalElements || 0)
        : setTotalItems(modifiedListingData?.length || 0)
    }
  }

  async function getSearchData(
    page = pagination.pageNumber,
    size = pagination.pageSize,
    search = '',
  ) {
    setLoading(true)
    await getData(page, size, search)
    setLoading(false)
  }

  async function getFilterData(
    page = pagination.pageNumber,
    size = pagination.pageSize,
    search = '',
    filter,
    sortData,
  ) {
    setLoading(true)
    await getData(page, size, search, filter, sortData)
    setLoading(false)
  }

  async function setFilterData(event) {
    setFilters((prev) => {
      const toReturn = giveGenericHandleChangedData(event, prev)
      getFilterData(0, null, null, toReturn)
      return toReturn
    })
  }

  async function getCustomFormData() {
    const formOptionsToSet = await getCustomFunctionData(customFormOptions, apiParam, routeParams)
    setCustomFormOptions(formOptionsToSet)
  }

  async function changePageNumber(page, pageSize = pagination.pageSize) {
    setLoading(true)
    await getData(page - 1, pageSize)
    setLoading(false)
  }

  const viewAddEditContextItems = {
    location,
    routeParams,
    listingItems: data,
    filters,
  }
  function handleAdd() {
    const newObject = getFullSaveObject(apiParam, data, totalItems)
    onViewAddFunction(
      apiParam,
      setShowEdit,
      modifyNewObjectData(newObject),
      setItemToEdit,
      navigate,
      setMode,
      'Add',
      viewAddEditContextItems,
    )
  }

  function handleEditClick(item) {
    onViewAddFunction(
      apiParam,
      setShowEdit,
      item,
      setItemToEdit,
      navigate,
      setMode,
      'Edit',
      viewAddEditContextItems,
    )
  }
  function handleRowClick(item) {
    if (onRowClickOverride) {
      onRowClickOverride({ setConfirmationModalProps, item })
      return
    }

    if (listingSettings.onRowClick) {
      listingSettings.onRowClick({ setConfirmationModalProps, item })
      return
    }
    onViewAddFunction(
      apiParam,
      setShowEdit,
      item,
      setItemToEdit,
      navigate,
      setMode,
      'View',
      viewAddEditContextItems,
    )
  }

  function handleCloseDialog(props = { force: false }) {
    const { force } = props
    if (!changedData) {
      setShowEdit(false)
      setChangedData(false)
      return
    }
    if (force) {
      setShowEdit(false)
      setChangedData(false)
      return
    }
    setConfirmationModalProps((prev) => ({
      ...prev,
      action: 'exit',
      body: `You have some unsaved changes. By exiting you will lose them. Do you want to continue?`,
      buttonColor: 'warning',
      visibility: true,
      visibilitySetter: setConfirmationModalProps,
      onSubmitFunctions: [
        () => {
          setShowEdit(false)
          setChangedData(false)
        },
      ],
    }))
  }

  function handleClosePopupActionsStates() {
    setPopupActionsStates((prev) => ({ ...prev, open: false }))
  }

  async function handleEnable(item) {
    const response = await EnableDisableEntity(apiParam, item, Setup, handleCloseDialog)
  }

  function handleEnableDisable(item) {
    setConfirmationModalProps((prev) => ({
      ...prev,
      action: item?.enabled ? 'disable' : 'enable',
      body: `Do you want to ${item?.enabled ? 'disable' : 'enable'} this entity?`,
      buttonColor: 'warning',
      visibility: true,
      visibilitySetter: setConfirmationModalProps,
      onSubmitFunctions: [() => handleEnable(item)],
    }))
  }

  function openCsvDialog() {
    setShowCsvDialog(true)
  }
  function closeCsvDialog() {
    setShowCsvDialog(false)
  }

  function handleTabChange(event, newValue) {
    navigate(`/setup/${StatsTabsToApiParamsMapper[newValue]}`)
  }

  function handleShowReorder() {
    setPopupActionsStates((prev) => ({
      ...prev,
      open: true,
      type: POPUP_ACTION_STATES_TYPES.reorderMentalState,
    }))
  }

  function openSettingsMenu(event) {
    setSettingsAnchor(event.currentTarget)
  }

  function handleCloseSettingsMenu() {
    setSettingsAnchor(null)
  }

  function goToMentalStates() {
    handleCloseSettingsMenu()
    navigate(`/setup/user-info/mental-state/mental-state-type`)
  }

  function clearFilters() {
    setFilters((prev) => {
      const toReturn = {}
      getFilterData(0, null, null, toReturn)
      return toReturn
    })
  }

  function tableComponents() {
    return (
      <>
        {showTabs && (
          <CoolerTabs
            value={StatsTabsToApiParamsMapper[apiParam]}
            onChange={handleTabChange}
            indicatorColor="secondary"
            textColor="white"
            sx={{ selected: { backgroundColor: 'white' } }}
          >
            <Tab label="User Badges" />
            <Tab label="User Stats" />
          </CoolerTabs>
        )}

        {apiParam === 'mental-state' && (
          <Button color="secondary" onClick={handleShowReorder}>
            Re-Order States
          </Button>
        )}
        <div className="d-flex justify-content-end align-items-center gap-2">
          {apiParam === 'mental-state' && (
            <IconButton onClick={openSettingsMenu}>
              <Settings />
            </IconButton>
          )}
        </div>
      </>
    )
  }

  function toggleMode() {
    setMode((prev) => (prev === 'View' ? 'Edit' : 'View'))
  }

  async function handleSortChange(sorter) {
    const sortVals = { sort: `${sorter?.column},${sorter?.state}` }
    setSortData(sortVals)
    getFilterData(null, null, filters?.search || '', null, sortVals)
  }

  const debounceSearch = debounce(async (event) => {
    setFilters((prev) => ({ ...prev, search: event.target.value }))
    await getSearchData(0, pagination.pageSize, event.target.value)
  }, 1000)
  const debounceFilterSet = debounce(async (event) => {
    await setFilterData(event)
  }, 1000)

  async function afterRefreshScript() {
    const response = await GetSettingsData(GENERIC_APIS.entity.getSummary, { entityType: apiParam })
    if (response) {
      postDataConfig?.afterSaveScript(apiParam, response.data)
    }
  }

  async function Setup() {
    setLoading(true)
    setFiltersAnchorEl(null)
    if (apiParam) {
      await Promise.all([
        getData(),
        getExtraDataForFormAndListing({ apiParam, locationParams: routeParams })
          ? getCustomFormData()
          : () => {},
      ])
    }
    setLoading(false)
  }

  async function Refresh() {
    if (postDataConfig?.afterSaveScript(apiParam)) {
      afterRefreshScript()
    }

    setLoading(true)
    if (apiParam) {
      await Promise.all([getData()])
    }
    setLoading(false)
  }

  function getCustomCardForGridListing(item) {
    return getGridCards(apiParam, rawApiParam, item, { onClick: handleEditClick })
  }

  function renderView() {
    switch (listingView) {
      case LISTING_VIEWS.list:
        return (
          <CCExtendedCSmartTable
            enabledParam={listingSettings?.enabledDisabledFieldForOpacityChangesInTable}
            // externalUnstyledComponents={tableComponents}
            // clickableRows
            // onRowClick={handleRowClick}
            excludeGenericFields={
              propDefaultExcludedColumns ||
              getExcludedListOfColumnsForSmartTable(apiParam, viewOnly)
            }
            customActions={customScopedActionsFunction(apiParam, {
              refresh: Refresh,
              onRowClick: handleRowClick,
              onEditClick: handleEditClick,
              setPopupActionsStates: setPopupActionsStates,
              handleEnableDisable: handleEnableDisable,
              setConfirmationModalProps,
            })}
            pagination={false}
            clickableRows={!viewOnly && clickableRows}
            onRowClick={handleRowClick}
            // selectable
            // externalFilters={externalFilters}
            // paginationProps={
            //   !!listingSettings?.pagination
            //     ? {
            //         activePage: (pagination?.pageNumber || 0) + 1,
            //         pages: totalPages,
            //         onActivePageChange: (activePage) => {
            //           changePageNumber(activePage)
            //         },
            //       }
            //     : null
            // }
            columns={columns}
            scopedColumns={
              propScopedColumns ||
              customScopedColumns(apiParam, customFormOptions, store, {
                routeParams,
              })
            }
            loading={loading}
            columnFilter
            editLabelChanger={editActionLabelChanger?.[apiParam] || 'Edit'}
            columnSorter={!!listingSettings?.pagination ? { external: true } : true}
            onSorterChange={handleSortChange}
            // onSelectedItemsChange={setMultiSelectedImages}
            // itemsPerPageSelect
            items={data}
            // onColumnFilterChange={filterChange}
            // handleFormViewClick={defaultActionsFunctionChanger(
            //   apiParam,
            //   GENERIC_DEFAULT_ACTIONS?.view,
            //   handleView,
            // )}
            // handleViewClick={defaultActionsFunctionChanger(
            //   apiParam,
            //   GENERIC_DEFAULT_ACTIONS?.edit,
            //   handleRowClick,
            // )}
            // handleEnable={defaultActionsFunctionChanger(
            //   apiParam,
            //   GENERIC_DEFAULT_ACTIONS?.enable_disable,
            //   handleEnableDisable,
            // )}
            ActionButtonWrapper={listingSettings?.ActionButtonWrapper}
            noUserNames={dontFetchUserNames(apiParam)}
            tableTagProps={{
              hover: true,
            }}
          />
        )
      case LISTING_VIEWS.grid:
        return (
          <GridListingComponent
            loading={loading}
            items={data}
            customItemBox={getCustomCardForGridListing}
            handleChange={handleEditClick}
            itemsPerBreakPoint={{ xs: 1, md: 2, lg: 3 }}
          />
        )
      default:
        return <></>
    }
  }

  function closeFiltersPopover() {
    setFiltersAnchorEl(null)
  }

  useEffect(() => {
    console.log(filters)
  }, [filters])

  useEffect(() => {
    console.log({ data })
  }, [data])

  useEffect(() => {
    if (!Boolean(apiParam)) {
      if (showTabs) {
        navigate(`${StatsTabsToApiParamsMapper[0]}`)
      } else {
        Object.entries(navDetails).forEach(([apiParam, values]) => {
          values?.valid() && navigate(apiParam)
        })
      }
    }
    Setup()
  }, [rawApiParam, refreshDataCountFromProps])

  return (
    <>
      {!hideFilterTableHeader && (
        <div
          className={`w-100 d-flex ${
            tableComponentsLength > 1 ? 'justify-content-between' : 'justify-content-end'
          } align-items-center gap-2 p-2 px-4 mb-1 rounded bg-body`}
        >
          {customHeaderComponents(apiParam, rawApiParam, {
            handleTabChange,
            handleShowReorder,
            filtersAnchorEl,
            setFiltersAnchorEl,
            closeFiltersPopover,
            filters,
            setFilters: setFilterData,
            handleAdd,
            debounceSearch,
            debounceSetFilter: debounceFilterSet,
            clearFilters,
            navigate,
            dropDownData: customFormOptions,
            rStore: store,
            setPopupActionsStates,
            listingView,
            setListingView,
            hideTableFilters,
            setConfirmationModalProps,
            routeParams,
            hideAddEntityButton,
            hideFilterButton,
            openCsvDialog,
            hideCsvSaveButton,
          })}
        </div>
      )}
      {renderView()}
      {!!listingSettings?.pagination && (
        <CCPagination
          paginationProps={{
            ...{
              activePage: (pagination?.pageNumber || 0) + 1,
              onActivePageChange: (activePage) => {
                changePageNumber(activePage)
              },
              pages: totalPages,
            },
          }}
          pageSize={pagination?.pageSize}
          handlePageSizeChange={(itemsPerPage) => {
            changePageNumber(1, itemsPerPage)
          }}
          hidePageSize={!!forceSize}
          itemsPerPageOptions={[...quoteOptionsList]}
        />
      )}
      {/* Form Edit Dialog */}
      <Dialog
        open={showEdit}
        onClose={handleCloseDialog}
        PaperComponent={GenericListingPageDialogPaper}
      >
        <DialogTitle>
          <h1>{entityTitle(apiParam)?.title}</h1>
        </DialogTitle>
        <UserStatsDialog
          entity={apiParam}
          data={itemToEdit}
          handleClose={handleCloseDialog}
          handleRefresh={Refresh}
          allData={data}
          customOptions={customFormOptions}
          storeData={store}
          mode={mode}
          toggleMode={toggleMode}
          setChangedData={setChangedData}
        />
      </Dialog>

      <Dialog
        open={popupActionsStates.open}
        onClose={handleClosePopupActionsStates}
        PaperComponent={GenericListingPageDialogPaper}
      >
        <PopActionsStatesComponent
          apiParam={apiParam}
          listingData={data}
          handleRefresh={Refresh}
          currentItem={popupActionsStates.itemInPerspective}
          handleClose={handleClosePopupActionsStates}
          customOptions={customFormOptions}
          storeData={store}
        />
      </Dialog>
      <Dialog
        open={showCsvDialog}
        onClose={closeCsvDialog}
        PaperComponent={GenericListingPageSmallDialogPaper}
      >
        <UploadCsvDialog apiParam={apiParam} closeDialog={closeCsvDialog} handleRefresh={Refresh} />
      </Dialog>
      <ConfirmationModal {...confirmationModalProps} newImplementation />
      <Menu
        open={Boolean(settingsAnchor)}
        anchorEl={settingsAnchor}
        onClose={handleCloseSettingsMenu}
      >
        <MenuItem onClick={goToMentalStates}>Mental State Types</MenuItem>
      </Menu>
    </>
  )
}

const apiPrmToMainColVar = {
  userBadge: 'User Badge',
  userStat: 'User Stats',
  mentalState: 'Mental State',
}
