import chroma from 'chroma-js'
import { AtomicBlockUtils } from 'draft-js'
import _ from 'lodash'
import moment from 'moment'
import React from 'react'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { toast } from 'react-toastify'
import { fileUploadToApi } from 'src/components/controlCenter/imageUpload/ImageUpload'
import { getGradientColors } from 'src/components/controlCenter/styledComponents'
import { ALL_ENTITY_TYPES } from 'src/config/common/genericListingAndFormConfig'
import { defaultBgImgId, fetchPaginatedFunction, getMediaImageUrl } from 'src/services'
import { GetSettingsData } from 'src/services/APIs/Settings/GetApis'
import { GENERIC_APIS } from 'src/services/genericApis'
import * as XLSX from 'xlsx'

export function getUserRoles() {
  const role = localStorage.getItem('authorities')
  return JSON.parse(role)
}

export function getIsTeacherAndCreatorData() {
  const role = getUserRoles()
  const isTeacher = role?.includes('ROLE_TEACHER')
  const isCreator = role?.includes('ROLE_CREATOR')
  return { isTeacher, isCreator }
}

export function getAllRolesInBoolean() {
  const role = getUserRoles()
  const isNormalUser = role?.includes('ROLE_USER')
  const isProfessionalUser = role?.includes('ROLE_PROFESSIONAL_USER')
  const isCreator = role?.includes('ROLE_CREATOR')
  const isTeacher = role?.includes('ROLE_TEACHER')
  const isDoctor = role?.includes('ROLE_DOCTOR')
  const isBusinessProspect = role?.includes('ROLE_BUSINESS_PROSPECT')
  const isBusinessCustomer = role?.includes('ROLE_BUSINESS_CUSTOMER')
  const isAdmin = role?.includes('ROLE_ADMIN')
  const isDeveloper = role?.includes('ROLE_DEVELOPER')
  const isAppManager = role?.includes('ROLE_APP_MANAGER')
  const isClinicProspect = role?.includes('ROLE_CLINIC_PROSPECT')
  const isClinicAssisstant = role?.includes('ROLE_CLINIC_ASSISSTANT')
  const isClinicManager = role?.includes('ROLE_CLINIC_MANAGER')
  return {
    isTeacher,
    isCreator,
    isDoctor,
    isBusinessCustomer,
    isNormalUser,
    isProfessionalUser,
    isAdmin,
    isDeveloper,
    isAppManager,
    isBusinessProspect,
    isClinicProspect,
    isClinicAssisstant,
    isClinicManager,
  }
}

export function notSetValidation(element) {
  return element ? element : <i>Not Set</i>
}

export function validateField(object, allObjects, field = 'id') {
  var toReturn = !allObjects.map((obj) => object[field] === obj[field])
  return toReturn
}

export function giveGenericHandleChangedData(
  eventObjOrPrevFuncOrValueToSet,
  prev,
  overrideNumberTransform,
  objectIsNotEventObject = false,
) {
  if (typeof eventObjOrPrevFuncOrValueToSet === 'function') {
    return eventObjOrPrevFuncOrValueToSet(prev)
  } else {
    if (objectIsNotEventObject) {
      return eventObjOrPrevFuncOrValueToSet
    } else {
      if (eventObjOrPrevFuncOrValueToSet.target.id)
        return {
          ...prev,
          [eventObjOrPrevFuncOrValueToSet.target.id]: getCorrectTargetValueForEvent(
            eventObjOrPrevFuncOrValueToSet,
            overrideNumberTransform,
          ),
        }
      else {
        return getCorrectTargetValueForEvent(eventObjOrPrevFuncOrValueToSet)
      }
    }
  }
}

export function genericHandleChange(event, setState) {
  setState((prev) => giveGenericHandleChangedData(event, prev))
}

export function genericHandleReactSelectChange(selectedValue, id, setter) {
  if (id) {
    genericHandleChange({ target: { value: selectedValue, type: 'react_select', id: id } }, setter)
  } else {
    setter(selectedValue)
  }
}

export function genericChangeNestedFields(value, outerId, setter) {
  setter((prev) => ({
    ...prev,
    [outerId]: typeof value === 'function' ? value(prev?.[outerId]) : value,
  }))
}

export function getCorrectTargetValueForEvent(e, overrideNumberTransform = false) {
  switch (e.target.type) {
    case 'checkbox':
      return e.target.checked
    case 'number':
      if (overrideNumberTransform) {
        return e.target.value
      }
      return e.target.value === '' ? null : Number(e.target.value)

    default:
      return e.target.value
  }
}

export function handleEventTargetChange(prev, event) {
  return {
    ...prev,
    [event.target.id]: getCorrectTargetValueForEvent(event),
  }
}

export function genericHandleNestedFields(event, outerId, setState) {
  if (typeof event === 'function') {
    genericChangeNestedFields((prev) => event(prev), outerId, setState)
  } else {
    genericChangeNestedFields((prev) => handleEventTargetChange(prev, event), outerId, setState)
  }
}

export function genericHandleNestedNestedFields(event, outerId, outerOuterId, setter) {
  setter((prev) => ({
    ...prev,
    [outerOuterId]: {
      ...(prev?.[outerOuterId] || {}),
      [outerId]: {
        ...(prev?.[outerOuterId]?.[outerId] || {}),
        [event.target.id]: giveGenericHandleChangedData(event, prev?.[outerOuterId]?.[outerId]),
      },
    },
  }))
}

export function genericHandleRichTextChange({ string, raw }, setter) {
  setter((prev) => {
    var toReturn = { ...prev }
    if (string.id) {
      toReturn = {
        ...toReturn,
        [string.id]: string.value,
      }
    }
    if (raw.id) {
      toReturn = {
        ...toReturn,
        [raw.id]: raw.value,
      }
    }
    return toReturn
  })
}

export function genericHandleChangeButtonClickData(btnTxtAndUri, btnTxtId, uriId, setter) {
  setter((prev) => {
    return {
      ...prev,
      [btnTxtId]: btnTxtAndUri?.btnTxt,
      [uriId]: btnTxtAndUri?.uri,
    }
  })
}

export function copyToClipBoard(field, text) {
  navigator.clipboard.writeText(text).then(
    () => {
      toast.success(`${field} Copied to clipboard successfully!`)
    },
    () => {
      toast.error(`Could not copy ${field} please try again`)
    },
  )
}

export function downloadAsExcelArrayOfObjects(data, filename = 'entity-data', ignoreFields = []) {
  const filteredData = data.map((item) => {
    // Create a new object without the ignored fields
    const newItem = {}
    for (const key in item) {
      if (!ignoreFields.includes(key)) {
        newItem[key] = item[key]
      }
    }
    return newItem
  })

  const ws = XLSX.utils.json_to_sheet(filteredData)
  const wb = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
  XLSX.writeFile(wb, `${filename}.xlsx`)
}

export function copyColor(colors) {
  var textToBeCopied = ''
  textToBeCopied =
    colors.type === 'solid'
      ? colors.colorIds.lastItem.hexCode
      : `linear-gradient(
        ${colors.degrees}deg,
        ${getGradientColors(colors.type, colors.colorIds)})`
  copyToClipBoard('Color', textToBeCopied)
  console.log(textToBeCopied)
}

export function checkIfStringHTML(str) {
  return /<(?=.*? .*?\/ ?>|br|hr|input|!--|wbr)[a-z]+.*?>|<([a-z]+).*?<\/\1>/i.test(str)
}

export function checkIfArray1HasArray2(A, B) {
  return A.every((i) => B.includes(i))
}

export function lightCheckIfArray1HasArray2(A, B) {
  return (
    A.map((arr) => {
      const secArray = B.find((Barr) => Barr === arr)
      return !!secArray
    }).find((foundMap) => foundMap === true) || false
  )
}

export function getIndexAfterDeletion(currentIndex, arrayLength) {
  const idealIndex = currentIndex - 1
  if (idealIndex >= 0) {
    return idealIndex
  } else {
    if (arrayLength - 1 > 0) {
      return idealIndex
    } else {
      return -1
    }
  }
}

export const asyncLocalStorage = {
  async setItem(key, value) {
    await null
    return localStorage.setItem(key, value)
  },
  async getItem(key) {
    await null
    return localStorage.getItem(key)
  },
}

export function getRoles() {
  return localStorage.getItem('authorities')
}

export function normalizeString(str) {
  return _.trim(str).replace(/\s+/g, ' ')
}

export async function handleEnableDisableEntity(body, entityType, afterSaveFunction) {
  if (body?.enabled) {
    const loadingToast = toast.loading(`Disabling`)
    const response = await GetSettingsData(GENERIC_APIS.entity.disable, {
      entityType: entityType,
      id: body.id,
    })
    if (response.status === 200) {
      toast.update(loadingToast, {
        render: `Disabled Successfully`,
        type: toast.TYPE.SUCCESS,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        autoClose: 2000,
        isLoading: false,
      })
      afterSaveFunction()
    }
  } else {
    const loadingToast = toast.loading(`Enabling`)
    const response = await GetSettingsData(GENERIC_APIS.entity.enable, {
      entityType: entityType,
      id: body.id,
    })
    if (response.status === 200) {
      toast.update(loadingToast, {
        render: `Enabled Successfully`,
        type: toast.TYPE.SUCCESS,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        autoClose: 2000,
        isLoading: false,
      })
      afterSaveFunction()
    }
  }
}

export function checkIfStringIsValid(str) {
  if (typeof str !== 'string') {
    return false
  }
  return str.length > 0
}

export function isValidNumber(value) {
  return typeof value === 'number' && Number.isFinite(value)
}

export function isValidInteger(value) {
  return isValidNumber(value) && Number.isInteger(value)
}

export function generateHexWithOpacity(opacity = 1) {
  // Generate a random color
  const randomColor = chroma.random()

  // Set the desired opacity
  const colorWithOpacity = chroma(randomColor).alpha(opacity)

  // Convert to hex and return
  return colorWithOpacity.hex()
}

function isColorUnique(color, existingColors) {
  // Check if the color is not similar to any existing color
  return !existingColors.some((existingColor) => chroma.deltaE(existingColor, color) < 20) // Adjust the threshold as needed
}

/**
 * Generate a hex color code not similar to existing colors.
 * @param {number} opacity - Opacity value between 0 and 1 (default is 1).
 * @param {string[]} existingColors - Array of existing hex color codes.
 * @returns {string} Hex color code.
 */
export function generateUniqueHexWithOpacity(opacity = 1, existingColors = []) {
  let generatedColor

  // Retry until a color is found that is not similar to any in the existingColors array
  while (true) {
    generatedColor = generateHexWithOpacity(opacity)

    // Check if the generated color is not similar to any existing color
    const isUnique = isColorUnique(generatedColor, existingColors)

    if (isUnique) {
      break
    }
  }

  return generatedColor
}

export const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay))

export async function handleDraftEditorDrop(e, editorState, afterUploadFunction = () => {}) {
  e.preventDefault()
  if (e.dataTransfer.files.length > 0) {
    const file = e.dataTransfer.files[0]
    if (file.type.startsWith('image')) {
      const newFile = await fileUploadToApi(file)
      const entityData = {
        src: `${getMediaImageUrl()}${newFile}`,
        height: file.data?.metadata?.height || '320px',
        width: file.data?.metadata?.width || '320px',
      }
      const entityKey = editorState
        .getCurrentContent()
        .createEntity('IMAGE', 'MUTABLE', entityData)
        .getLastCreatedEntityKey()
      const newEditorState = AtomicBlockUtils.insertAtomicBlock(editorState, entityKey, ' ')

      afterUploadFunction(newEditorState)
    }
  }
}

export async function loadOptionsForAsyncPaginate(
  search,
  loadedOptions,
  {
    getFunction,
    apiUrl,
    otherParams,
    transformDataToReturn = (data) => data,
    setLoading = () => {},
    ...additional
  },
) {
  let page = additional?.page || 0

  setLoading && setLoading(true)
  const resp = await getFunction(apiUrl, {
    page: additional?.page || 0,
    size: additional?.size || null,
    search: search,
    ...otherParams,
  })
  var dataToReturn = transformDataToReturn(resp.data.content)
  setLoading && setLoading(false)

  let nextPage = page + 1


  return {
    options: dataToReturn,
    hasMore: !resp.data.last,
    additional: {
      ...additional,
      getFunction,
      apiUrl,
      otherParams,
      setLoading,
      transformDataToReturn,
      page: nextPage,
    },
  }
}

export function isValidYouTubeUrl(url) {
  const pattern = /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/
  return pattern.test(url)
}

export function isValidUrl(url) {
  try {
    new URL(url)
    return true
  } catch (error) {
    return false
  }
}

export function getVideoFileMetaData(file) {
  return new Promise((resolve, reject) => {
    var video = document.createElement('video')
    video.preload = 'metadata'

    video.onloadedmetadata = function () {
      window.URL.revokeObjectURL(video.src)
      resolve({
        duration: video.duration,
        width: video.videoWidth,
        height: video.videoHeight,
      })
    }

    video.onerror = function () {
      reject('Error loading video file')
    }

    video.src = URL.createObjectURL(file)
  })
}

export function giveMediaTypeBasedOnMimeType(mimeType) {
  if (mimeType.includes('image')) {
    return 'image'
  } else if (mimeType.includes('video')) {
    return 'video'
  } else if (mimeType.includes('audio')) {
    return 'audio'
  } else {
    return 'file'
  }
}

export const openInNewTab = (url) => {
  window.open(url, '_blank')
}

export function getItemTitle(item) {
  return item?.title || item?.name || item?.label || item?.internalTitle || 'No Title'
}

// export function capitalize(str) {
//   return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
// }

export function isCommonResponseSuccessful(codeReceived) {
  if (codeReceived === 200 || codeReceived === 2000 || codeReceived === 0) {
    return true
  }
  return false
}

export async function checkIfSlugValid(
  oldSlug,
  newSlug,
  customAPI = GENERIC_APIS.entity.slug.checkAvailability,
  customAPIParams = {},
) {
  var slugValidationToReturn = { valid: false, unavailable: false }

  if (Boolean(newSlug)) {
    if (newSlug === oldSlug) {
      return { valid: true, unavailable: false }
    }
    const pattern = /^[a-z\-]+$/ // match only a-z and hyphen
    const matchesPattern = pattern.test(newSlug)
    if (matchesPattern) {
      const response = await GetSettingsData(customAPI, {
        slug: newSlug,
        ...customAPIParams,
      }) //fire api to check if input is unique
      if (response?.status === 200) {
        const responseCode = response?.data?.code
        const responseData = response?.data?.data
        if (isCommonResponseSuccessful(response.data.code)) {
          slugValidationToReturn = { valid: true, unavailable: !responseData?.available }
        }
        if (responseCode === 501 || responseCode === 501) {
          toast.error('Entity does not support slugs.')

          slugValidationToReturn = { valid: false, unavailable: false }
        }
      } else {
        toast.error('Error setting URL')
      }
    } else {
      slugValidationToReturn = { valid: false, unavailable: false }
    }
  } else {
    slugValidationToReturn = { valid: false, unavailable: false }
  }

  console.log({ slugValidationToReturn })
  return slugValidationToReturn
}

export const getAgeFromDobWithTime = (dob) => {
  if (dob) {
    let today = new Date()
    let birthDate = new Date(dob?.substring(0, 10))
    let age = today.getFullYear() - birthDate.getFullYear()
    let m = today.getMonth() - birthDate.getMonth()
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--
    }
    return age
  } else {
    return ''
  }
}

export async function getMentorNames(
  listData,
  mentorIdField = 'mentorId',
  mentorNameField = 'mentorId',
) {
  const mentorIdsToUse = listData?.map((item) => item?.[mentorIdField])
  if (mentorIdsToUse?.length <= 0) {
    return []
  }

  const mentorsResponse = await GetSettingsData(GENERIC_APIS.entity.getPaginated, {
    entityType: ALL_ENTITY_TYPES.mentor,
    ids: mentorIdsToUse?.join(','),
  })
  if (mentorsResponse?.status !== 200) return listData

  const foundMentors = mentorsResponse?.data?.content

  return listData?.map((item) => {
    const listMentor = foundMentors?.find((mentor) => mentor?.id === item?.[mentorIdField])
    return { ...item, [mentorNameField]: listMentor?.title }
  })
}

export async function getClinicNames(
  listData,
  clinicIdField = 'clinicId',
  clinicNameField = 'clinicName',
) {
  const clinicIdsToUse = listData
    ?.map((listItem) => listItem?.[clinicIdField])
    ?.filter((id) => !!id)
  const foundClinics = await fetchPaginatedFunction(GENERIC_APIS.entity.getPaginated, {
    entityType: ALL_ENTITY_TYPES.clinic,
    ids: clinicIdsToUse?.join(','),
  })
  return listData?.map((listItem) => {
    const foundClinic = foundClinics?.find((clinic) => clinic?.id === listItem?.[clinicIdField])
    return { ...listItem, [clinicNameField]: foundClinic?.title }
  })
}

export async function getClinicBrandNames(
  listData,
  clinicIdField = 'clinicBrandId',
  clinicNameField = 'clinicBrandId',
) {
  const clinicBrandIds = listData
    ?.map((listItem) => listItem?.[clinicIdField])
    ?.filter((id) => !!id)
  const foundClinicBrands = await fetchPaginatedFunction(GENERIC_APIS.entity.getPaginated, {
    entityType: ALL_ENTITY_TYPES.clinicBrand,
    ids: clinicBrandIds?.join(','),
  })
  return listData?.map((listItem) => {
    const foundClinicBrand = foundClinicBrands?.find(
      (clinic) => clinic?.id === listItem?.[clinicIdField],
    )
    return { ...listItem, [clinicNameField]: foundClinicBrand?.title }
  })
}

export const isValidHttpUrl = (string) => {
  let url
  try {
    url = new URL(string)
  } catch (_) {
    return false
  }
  return url.protocol === 'http:' || url.protocol === 'https:'
}

export const getImageLink = (priorityImage, secondaryImage, returnFallBack = true) => {
  let image
  if (priorityImage) {
    if (isValidHttpUrl(priorityImage)) {
      image = priorityImage
    } else {
      image = `${getMediaImageUrl()}${priorityImage}`
    }
  } else if (secondaryImage) {
    if (isValidHttpUrl(secondaryImage)) {
      image = secondaryImage
    } else {
      image = `${getMediaImageUrl()}${secondaryImage}`
    }
  } else if (returnFallBack) {
    image = `${getMediaImageUrl()}${defaultBgImgId}`
  }

  return image
}

export function getMomentDateFromMilliseconds(milliseconds) {
  const inSeconds = milliseconds / 1000

  const seconds = Math.floor(inSeconds % 60)
  const minutes = Math.floor((inSeconds / 60) % 60)
  const hours = Math.floor((inSeconds / 3600) % 24)
  // console.log({
  //   milliseconds,
  //   momDate: moment(
  //     `2022-04-17T${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(
  //       seconds,
  //     ).padStart(2, '0')}`,
  //   ),
  // })
  return moment(
    `2022-04-17T${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(
      seconds,
    ).padStart(2, '0')}`,
  )
}

export function calculateMinWidthAndHeight(decimalAspectRatio) {
  if (decimalAspectRatio <= 0) {
    throw new Error('Aspect ratio must be a positive number.')
  }

  // Calculate the minimum width and height
  const gcd = (a, b) => (b < 1e-5 ? a : gcd(b, a % b))
  const denominator = 1 / decimalAspectRatio
  const minWidth = 1 / gcd(1, denominator)
  const minHeight = denominator / gcd(1, denominator)

  return { minWidth, minHeight }
}

export function isValidPhoneNumberString(phoneNumber) {
  const phoneNumberRegex = /^\d{10}$/
  return isValidPhoneNumber(phoneNumber) || phoneNumberRegex.test(phoneNumber)
}

export function isValidEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return emailRegex.test(email)
}

export function putClinicObjectsInRelevantClinicBrandObjects(clinicBrands, clinics) {
  return clinicBrands.map((clinicBrand) => {
    const relevantClinics = clinics.filter((clinic) => clinic?.clinicBrandId === clinicBrand?.id)
    return {
      ...clinicBrand,
      associatedClinics: relevantClinics,
    }
  })
}

export function downloadExcelSheetFromRows({ rows, titleOfSheet, workSheetTitle }) {
  const worksheet = XLSX.utils.json_to_sheet(rows)
  const workbook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workbook, worksheet, workSheetTitle)
  XLSX.writeFile(workbook, `${titleOfSheet}.xlsx`, { compression: true })
}

export function downloadExcelSheetFromSheetJsWbData({ workbookData, titleOfSheet }) {
  XLSX.writeFile(workbookData, `${titleOfSheet}.xlsx`, { compression: true })
}
export function getPercentage(num, denom, decimalNumber = 2) {
  if (!denom) return null
  return parseFloat(((num / denom) * 100).toFixed(decimalNumber))
}

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
