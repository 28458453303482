import { CFormLabel } from '@coreui/react-pro'
import { Avatar } from '@mui/material'
import { useMemo, useState } from 'react'
import { components } from 'react-select'
import { getMediaImageUrl } from 'src/services'
import { GetData } from 'src/services/APIs/DailyPlay/GetLists'
import { isValidEmail, isValidPhoneNumberString } from 'src/utilities/generalUtils'
import { useAppSettingsStore } from 'src/utilities/hooks/useAppSettingsStore'
import { CustomAsyncPaginate } from 'src/views/plugins/calendar/components/CustomSelect'
import { selectTypes } from '../FormComponents'
import { UICGetValue, UICValidateSelectedUsers } from './user-input-utils'

const selectAvatarDimensions = { width: 30, height: 30 }

export const UserInputOption = (props) => {
  const { children, ...rest } = props
  const [appSettings, appSettingsDispatch] = useAppSettingsStore()
  const roles = appSettings?.allRoles

  const currentOption = props.data
  const roleTitles = currentOption?.authorities?.map(
    (role) => roles.find((rol) => rol?.name === role)?.title || role,
  )

  return (
    <components.Option {...rest}>
      <div className="d-flex justify-content-start align-items-center gap-4">
        {!currentOption?.__isNew__ ? (
          <>
            <Avatar src={`${getMediaImageUrl()}${currentOption?.profilePicId}`} />
            <div className="d-flex flex-column align-items-start justify-content-center gap-1">
              {children}
              {currentOption?.email && (
                <span className="fw-light fs-8" span={{ margin: 0 }}>
                  {currentOption?.email}
                </span>
              )}
              {currentOption?.mobile && (
                <span className="fw-light fs-8" span={{ margin: 0 }}>
                  {currentOption?.mobile}
                </span>
              )}
              <div className="d-flex justify-content-start align-items-center">
                Roles: {roleTitles?.join(', ')}
              </div>
            </div>
          </>
        ) : (
          <>
            <Avatar />
            <span>Invite: {currentOption?.value}</span>
          </>
        )}
      </div>
    </components.Option>
  )
}

export const UserMultiValueLabelOption = (props) => {
  const { children, ...rest } = props

  const currentOption = props.data

  return (
    <components.MultiValueLabel {...rest}>
      <div className="d-flex justify-content-start align-items-center gap-2">
        <Avatar
          sx={{ ...selectAvatarDimensions }}
          src={`${getMediaImageUrl()}${currentOption?.profilePicId}`}
        />
        <div>{children}</div>
        {currentOption?.email && (
          <span className="fw-light fs-8" span={{ margin: 0 }}>
            {currentOption?.email}
          </span>
        )}
        {currentOption?.mobile && (
          <span className="fw-light fs-8" span={{ margin: 0 }}>
            {currentOption?.mobile}
          </span>
        )}
      </div>
    </components.MultiValueLabel>
  )
}

export const UserSelectedOption = ({ children, ...props }) => {
  const currentOption = props.data
  console.log({ props })
  return (
    <components.SingleValue {...props}>
      <div className="d-flex justify-content-start align-items-center gap-2">
        <Avatar
          sx={{ ...selectAvatarDimensions }}
          src={`${getMediaImageUrl()}${currentOption?.profilePicId}`}
        />
        <div>{children}</div>
        {currentOption?.email && (
          <span className="fw-light fs-8" span={{ margin: 0 }}>
            {currentOption?.email}
          </span>
        )}
        {currentOption?.mobile && (
          <span className="fw-light fs-8" span={{ margin: 0 }}>
            {currentOption?.mobile}
          </span>
        )}
      </div>
    </components.SingleValue>
  )
}

export default function UserInputComponent({
  value,
  onChange,
  disabled,
  getOptionLabel = (object) => object.title || object.firstName || object.user_name,
  SelectComponent = CustomAsyncPaginate,
  invalidPhoneEmailErrorMessage = 'You need to enter a valid email or a phone number',
  label = 'User',
  roleFilter = 'ROLE_USER',
  ...props
}) {
  const [userIdValueInput, setUserIdValueInput] = useState('')
  const internalValue = useMemo(() => {
    return UICGetValue(value, props.isMulti)
  }, [value, props.isMulti])

  const menuValidation = useMemo(() => {
    const isPhoneNumberValid = isValidPhoneNumberString(userIdValueInput)
    const isEmailValid = isValidEmail(userIdValueInput)
    var validation = { valid: false, error: '', color: 'text-danger' }
    if (!userIdValueInput) {
      return UICValidateSelectedUsers(value, props.isMulti, props.required)
    } else {
      if (isPhoneNumberValid || isEmailValid) {
        validation = { valid: true, error: '', color: 'text-success' }
      } else {
        validation = {
          valid: false,
          error: invalidPhoneEmailErrorMessage,
          color: 'text-info',
        }
      }
    }
    return validation
  }, [userIdValueInput, invalidPhoneEmailErrorMessage, props.isMulti, props.required, value])

  async function loadUsers(search, loadedOptions, { page }) {
    const resp = await GetData('roles/users', {
      page,
      // search: search,
      search: search?.replace(/^\+/, ''),
      role: roleFilter,
    })
    var dataToReturn = resp.data.content
    return {
      options: dataToReturn,
      hasMore: !resp.data.last,
      additional: {
        page: resp.data?.pageable?.pageNumber + 1,
      },
    }
  }

  return (
    <div>
      <CFormLabel>{label}</CFormLabel>
      <SelectComponent
        {...props}
        value={internalValue}
        loadOptions={loadUsers}
        getOptionValue={(object) => object.userId}
        getOptionLabel={getOptionLabel}
        selectType={selectTypes.object}
        menuIsOpen={menuValidation?.valid}
        debounceTimeout={500}
        onInputChange={(inp) => {
          setUserIdValueInput(inp)
        }}
        onChange={onChange}
        isDisabled={disabled}
        // components={{ Option: UserInputOption, SingleValue: UserSelectedOption }}
        components={{
          Option: UserInputOption,
          SingleValue: UserSelectedOption,
          MultiValueLabel: UserMultiValueLabelOption,
        }}
        additional={{ page: 0 }}
        debounce={500}
      />
      {!menuValidation?.valid && menuValidation?.error && (
        <small className={menuValidation?.color}>{menuValidation?.error}</small>
      )}
    </div>
  )
}
