import { CFormInput } from '@coreui/react-pro'
import { Grid } from '@mui/material'
import moment from 'moment'
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { CustomReactSelect, selectTypes } from 'src/components/controlCenter/FormComponents'
import GridSectionWrapper from 'src/components/controlCenter/common/sectionComponents/GridSectionWrapper'
import SocialConnectionsComponent from 'src/components/controlCenter/genericListing/users/SocialConnectionsComponent'
import ImageUpload from 'src/components/controlCenter/imageUpload/ImageUpload'
import { GetSettingsData } from 'src/services/APIs/Settings/GetApis'
import { USERS_API_SIGNATURES_MAP } from 'src/services/apiSignatureMaps/users/apis'
import { GENERIC_APIS } from 'src/services/genericApis'
import { ALL_ENTITY_TYPES_FROM_CONSTANTS, ALL_GENDERS } from 'src/utilities/constants'
import { useScreenSize } from 'src/utilities/hooks/useScreenSize'

export default function UsersBasicDetailsTab({
  disabled,
  userObject,
  customOptions,
  onboardingName,
  setOnboardingName,
  hideContentPreferences = false,
  hideOtherDetails = false,
}) {
  const { width } = useScreenSize()

  async function getTeacherById(id) {
    const resp = await GetSettingsData(GENERIC_APIS.entity.details, {
      entityType: ALL_ENTITY_TYPES_FROM_CONSTANTS.mentor,
      id: id,
    })
    if (resp.status === 200) {
      return resp.data.name
    }
  }

  async function getMetaTagById(id) {
    const resp = await GetSettingsData(GENERIC_APIS.entity.details, {
      entityType: ALL_ENTITY_TYPES_FROM_CONSTANTS.tag,
      id: id,
    })
    if (resp.status === 200) {
      return resp.data.title || resp.data.tag
    }
  }

  async function getSharedLocationById(id) {
    const resp = await GetSettingsData(USERS_API_SIGNATURES_MAP.location.get, {
      locationId: id,
    })
    if (resp.status === 200) {
      return resp.data.location
    }
  }

  async function getOnboardingName(id, type) {
    var onboardingNameToSet = ''
    switch (type) {
      case 'teacher':
        onboardingNameToSet = await getTeacherById(id)
        break
      case 'genre':
      case 'tag':
        onboardingNameToSet = await getMetaTagById(id)
        break
      case 'location':
        onboardingNameToSet = await getSharedLocationById(id)
        break
      default:
        break
    }

    setOnboardingName(onboardingNameToSet)
  }

  useEffect(() => {
    getOnboardingName(
      userObject.personalize.studentPersonalize.onboardExplorePreference.refId,
      userObject.personalize.studentPersonalize.onboardExplorePreference.refType,
    )
  }, [])

  console.log({
    email: userObject?.email,
    mobile: userObject?.mobile,
    dob: userObject?.dob,
    age: moment().diff(moment(userObject?.dob), 'years'),
  })

  function userSuperBasicDetails() {
    return (
      <>
        <Grid item xs={12} md={6}>
          <CFormInput
            id="userNumber"
            label="User Number"
            disabled={disabled}
            value={userObject?.userNumber|| ""}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomReactSelect
            id="gender"
            label="Gender"
            disabled={disabled}
            value={userObject?.gender}
            options={ALL_GENDERS}
            getOptionValue={(opt) => opt?.id}
            getOptionLabel={(opt) => opt?.title}
            placeholder="No gender entered..."
            selectType={selectTypes.string}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CFormInput
            id="email"
            label="Email"
            disabled={disabled}
            value={userObject?.email || ''}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CFormInput
            id="mobile"
            label="Mobile"
            disabled={disabled}
            value={userObject?.mobile || ''}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CFormInput id="dob" label="DOB" disabled={disabled} value={userObject?.dob || ''} />
        </Grid>
        <Grid item xs={12} md={6}>
          <CFormInput
            id="age"
            label="Age"
            disabled={disabled}
            value={moment().diff(moment(userObject?.dob), 'years')}
          />
        </Grid>
      </>
    )
  }

  return (
    <GridSectionWrapper shadow={false}>
      <Grid item xs={12}>
        <GridSectionWrapper heading="Personal Details">
          <Grid item xs={12} md={9}>
            <GridSectionWrapper shadow={false}>
              {width >= 768 ? (
                userSuperBasicDetails()
              ) : (
                <Grid item xs={12}>
                  <ImageUpload
                    imageId={userObject?.profilePicId}
                    // onChange={(imageId) => {
                    //   console.log({ imageId })
                    //   handleImageChange(imageId, 'profilePicId')
                    // }}
                    disabled={disabled}
                  />
                </Grid>
              )}
            </GridSectionWrapper>
          </Grid>
          <Grid item xs={12} md={3}>
            <GridSectionWrapper shadow={false}>
              {width < 768 ? (
                userSuperBasicDetails()
              ) : (
                <Grid item xs={12} display="flex" justifyContent="flex-end">
                  <ImageUpload
                    imageId={userObject?.profilePicId}
                    // onChange={(imageId) => {
                    //   console.log({ imageId })
                    //   handleImageChange(imageId, 'profilePicId')
                    // }}
                    disabled={disabled}
                  />
                </Grid>
              )}
            </GridSectionWrapper>
          </Grid>
        </GridSectionWrapper>
      </Grid>
      {!hideContentPreferences && (
        <Grid item xs={12}>
          <GridSectionWrapper heading="Content Preferences">
            <Grid item xs={12} md={6} lg={4}>
              <CustomReactSelect
                id="userLanguage"
                label="User Language"
                disabled={disabled}
                value={userObject?.personalize?.studentPersonalize?.userLanguage}
                options={customOptions?.languages}
                selectType={selectTypes.string}
                getOptionValue={(opt) => opt?.id}
                getOptionLabel={(opt) => opt?.languageName}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <CustomReactSelect
                isMulti
                id="contentLanguages"
                label="Content Languages"
                disabled={disabled}
                value={userObject?.personalize?.studentPersonalize?.contentLanguages}
                options={customOptions?.languages}
                selectType={selectTypes.string}
                getOptionValue={(opt) => opt?.id}
                getOptionLabel={(opt) => opt?.languageName}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <CustomReactSelect
                isMulti
                id="tags"
                label="Tags"
                disabled={disabled}
                value={userObject?.tags}
                options={customOptions?.userTags}
                selectType={selectTypes.string}
                getOptionValue={(opt) => opt?.id}
                getOptionLabel={(opt) => opt?.tag}
              />
            </Grid>
          </GridSectionWrapper>
        </Grid>
      )}
      <Grid item xs={12}>
        <GridSectionWrapper heading="Location">
          <Grid item xs={12} md={6}>
            <CustomReactSelect
              id="country"
              label="Country"
              disabled={disabled}
              value={userObject?.location?.countryCode || null}
              options={customOptions?.countries}
              getOptionLabel={(option) => option?.title}
              getOptionValue={(option) => option?.id}
              selectType={selectTypes.string}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CFormInput label="City" value={userObject?.location?.city || ""} disabled={disabled} />
          </Grid>
          <Grid item xs={12} md={6}>
            <CFormInput label="Address" value={userObject?.location?.address|| ""} disabled={disabled} />
          </Grid>
        </GridSectionWrapper>
      </Grid>
      {!hideOtherDetails && (
        <>
          <Grid item xs={12} md={6} lg={4}>
            <CustomReactSelect
              isMulti
              id="authorities"
              label="Roles"
              disabled={disabled}
              value={userObject?.authorities}
              options={customOptions?.allRoles}
              getOptionValue={(option) => option?.id}
              getOptionLabel={(option) => option?.title}
              selectType={selectTypes.string}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Grid item xs={12}>
              Social Connections
              <div className="mt-2">
                <SocialConnectionsComponent userData={userObject} />
              </div>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <span>
              Referred By:
              {userObject?.referredByUser && (
                <Link
                  to={`/role-based-entities/${ALL_ENTITY_TYPES_FROM_CONSTANTS.users}/${userObject?.referredByUser?.userId}`}
                >
                  {userObject?.referredByUser?.name}
                </Link>
              )}
            </span>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            {userObject?.personalize?.studentPersonalize?.onboardExplorePreference ? (
              <span>
                Onboarding:
                {userObject.personalize.studentPersonalize.onboardExplorePreference?.refType} -
                {onboardingName}
              </span>
            ) : (
              <i>None</i>
            )}
          </Grid>
        </>
      )}
    </GridSectionWrapper>
  )
}
